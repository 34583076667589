<template>
  <notification :id="notificationId" :useTimeout="useTimeout" :timeout="timeout"
    :resetTimeoutOnHover="resetTimeoutOnHover" :showClose="showClose">
    <div class="w-72 bg-white border-2 border-gray-950 p-2.5 shadow-box space-y-1.5">
      <div class="relative flex items-center justify-between pb-1 border-b-2 border-grey-blue">
        <h3 class="font-bold leading-none uppercase text-grey-blue">
          <span class="relative normal-case transition-all duration-500" :class="activeAnimationClass">{{ qtyAdded
          }}x</span>
          Product Added
        </h3>
        <cart class="relative">
          <template slot-scope="{ totalItems }">
            <i class="text-xl fas fa-shopping-basket"></i>
            <span
              class="flex justify-center items-center absolute left-1/2 top-0 bg-orange text-xxs text-white text-center font-bold leading-none p-0.5 rounded pointer-events-none">
              <span>{{ totalItems }}</span>
            </span>
          </template>
        </cart>
      </div>
      <div class="flex">
        <figure class="flex justify-center items-center w-14.5 h-14.5 flex-shrink-0 border-2 border-grey-pale p-1 mr-3">
          <img class="max-h-full" :src="productData.imgSrc" :alt="productData.sku" v-product-placeholder="{ width: '80%', opacity: '0.4' }" />
        </figure>
        <div class="min-w-0 space-y-1">
          <h3 class="text-sm leading-tight break-words">{{ productData.title }}</h3>
          <span class="block text-xs leading-none text-grey-dark">{{ productData.sku }}</span>
          <span class="block text-xs leading-none text-grey-blue">Qty in cart: <span class="font-bold text-orange">{{
            productData.qtyInCart }}</span></span>
        </div>
      </div>
      <div class="flex items-center justify-between">
        <span class="font-bold text-grey-blue">Sub Total</span>
        <cart class="font-bold text-orange">
          <template slot-scope="{ subTotal }">{{ subTotal }}</template>
        </cart>
      </div>
      <button type="button" class="btn w-full text-sm py-1.5" @click.prevent="handleClick">See More</button>
    </div>
  </notification>
</template>

<script>
import Notification from './Notification'
import Cart from './Cart'

import { mapActions, mapGetters, mapState } from 'vuex'
import { LIGHTBOX } from '../store/modules/feedback/_constants'

import notificationPropsMixin from '../mixins/notificationPropsMixin'

export default {
  name: 'added-to-cart-notification',
  mixins: [notificationPropsMixin],
  components: { Notification, Cart },
  props: {
    productId: {
      type: [Number, String],
      required: true
    },
    timeout: {
      type: Number,
      default: 4000 // ms
    }
  },
  data() {
    return {
      animationTimeoutId: null,
      animationClasses: ['qty-has-changed', 'qty-has-changed--reset'],
      activeAnimationClass: ''
    }
  },
  computed: {
    ...mapState('notifications', {
      productNotificationPrefix: state => state.productPrefix
    }),
    ...mapGetters('cart', ['findProductById']),
    ...mapGetters('feedback', ['addedToCartFeedbackMethod']),
    notificationId() {
      return this.productNotificationPrefix + this.productId
    },
    isActive() {
      return this.$store.getters['notifications/isActive'](this.notificationId)
    },
    productData() {
      const product = this.findProductById(this.productId)
      if (!product) return {}
      const { image, title, sku, qty, price } = product
      return {
        imgSrc: image.url,
        qtyInCart: qty,
        title,
        sku,
        price
      }
    },
    qtyAdded() {
      return this.$store.getters['cart/qtyAdded'](this.productId)
    }
  },
  methods: {
    ...mapActions('cart', ['removeQtyAdded']),
    ...mapActions('feedback', ['changeAddedToCartFeedbackMethod']),
    ...mapActions('notifications', ['removeNotification']),
    ...mapActions('addedToCartPopup', ['showPopup']),
    handleClick() {
      this.changeAddedToCartFeedbackMethod(LIGHTBOX)
      this.removeNotification(this.notificationId)
      this.showPopup(this.productId)
    },
    async toggleQtyChangedAnimation(qty) {
      // We don't want to change the animation in any way if this notification isn't active or the user has only added 1 item.
      if (!this.isActive || qty < 2) return

      // If there is already an animation taking place, remove the timeout for this.
      if (this.animationTimeoutId) {
        clearTimeout(this.animationTimeoutId)
        this.animationTimeoutId = null
      }

      // Here we toggle between the two animation classes. I'm using this method so "reset" the animation.
      // Following some ideas from this article: https://css-tricks.com/restart-css-animation/
      this.activeAnimationClass = this.animationClasses[qty % 2]
      this.animationTimeoutId = setTimeout(() => {
        this.activeAnimationClass = ''
        this.animationTimeoutId = null
      }, 1500)
    }
  },
  watch: {
    qtyAdded(val) {
      this.toggleQtyChangedAnimation(parseInt(val))
    },
    isActive(newVal, oldVal) {
      // If the new value is false and the old value is true, then the notification has just closed
      if (!newVal && oldVal) {
        this.removeQtyAdded(this.productId)
        this.$emit('notification-closed')
      }
    },
    addedToCartFeedbackMethod() {
      // Reset the qty just added whenever we change the feedback method
      this.removeQtyAdded(this.productId)
    }
  }
}
</script>

<style lang="scss" scoped>
.qty-has-changed,
.qty-has-changed--reset {
  @apply text-green-700;

  &::after {
    content: '';
    @apply block absolute left-1/2 top-1/2 w-2 h-2 bg-green-700 rounded-full;
  }
}

.qty-has-changed {
  &::after {
    animation: qtyAddedChanged 1.5s cubic-bezier(0.22, 1.03, 0.88, 1);
  }

  &--reset {
    &::after {
      animation: qtyAddedChangedReset 1.5s cubic-bezier(0.22, 1.03, 0.88, 1);
    }
  }
}
</style>
