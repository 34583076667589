import { IMAGE_PLACEHOLDER, IMAGE_SPECIAL_PART_PLACEHOLDER } from '../store/constants'

function renderPlaceholder(el, styles,isSpecialOrder=false) {
  if (typeof styles === 'object' && styles !== null) {
    for (const style in styles) {
      el.style[style] = styles[style]
    }
  }
  el.src = isSpecialOrder ? IMAGE_SPECIAL_PART_PLACEHOLDER : IMAGE_PLACEHOLDER
}

export default {
  name: 'product-placeholder',
  object: {
    bind: function (el, binding) {
      const {styles,isSpecialOrder} = binding.value

      if (!el.src) {
        renderPlaceholder(el, styles,isSpecialOrder)
      }

      window.addEventListener('load', () => {
        if (!el.src) {
          renderPlaceholder(el, styles,isSpecialOrder)
        }
      })

      el.onerror = (event) => {
        el.onerror = null
        renderPlaceholder(el, styles,isSpecialOrder)
      }
    }
  }
}
