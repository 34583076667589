<template>
  <div class="w-full h-full" :class="{ 'overflow-hidden': isZooming, 'cursor-zoom-in': !hasError }"
    @click="openImagePopup">

    <img ref="image" class="object-contain w-full h-full"
      :src="!hasError ? src : placeholder" :alt="alt" @error="hasError = true" @mouseenter="zoomStart" @mousemove="zoomIn"
         v-product-placeholder="{styles: isSpecialOrder ? placeholderSpecialPartStyles:placeholderStyles,isSpecialOrder}"
      @mouseleave="zoomEnd">

    <div v-show="isZooming" class="absolute top-0 left-0 w-full h-full bg-no-repeat pointer-events-none" :style="{
      'background-image': 'url(' + src + ')',
      'background-position': `${this.bgPosX}% ${this.bgPosY}%`
    }"></div>

    <portal v-if="!hasError" to="lightbox">
      <div v-show="showImagePopup"
        class="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-screen p-4 bg-gray-950/80"
        @click="closeImagePopup">
        <img class="max-w-full max-h-full" :src="src" :alt="alt">
      </div>
    </portal>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import featuredImageModule from '../store/modules/featuredImage/featuredImage'
const { state, getters, mutations, actions } = featuredImageModule
import { IMAGE_PLACEHOLDER, IMAGE_SPECIAL_PART_PLACEHOLDER } from '../store/constants'

export default {
  name: 'img-featured',
  props: {
    moduleId: {
      type: String
    },
    moduleIsSpecialOrder: {
      type: String,
      default:0
    },
    placeholderStyles: {
      type: Object,
      default: () => ({ width: '70%', maxWidth: '4.5rem', opacity: '0.4', margin: '0 auto' })
    },
    placeholderSpecialPartStyles: {
      type: Object,
      default: () => ({opacity: '0.4', margin: '0 auto' })
    },
    defaultSrc: {
      type: String
    },
    defaultAlt: {
      type: String
    }
  },
  data() {
    return {
      placeholder: IMAGE_PLACEHOLDER,
      hasError: false,
      canHover: true,
      imageRef: '',
      width: '',
      height: '',
      mouseX: '',
      mouseY: '',
      bgPosX: '',
      bgPosY: '',
      isZooming: false,
      showImagePopup: false,
      isSpecialOrder: parseInt(this.moduleIsSpecialOrder)
    }
  },
  computed: {
    ...mapState({
      moduleName(state) {
        return state.modulePrefixes.featuredImage + (this.moduleId || '')
      }
    }),
    src() {
      return this.$store.getters[`${this.moduleName}/src`] || this.defaultSrc
    },
    alt() {
      return this.$store.getters[`${this.moduleName}/alt`] || this.defaultSrc
    }
  },
  methods: {
    setRef() {
      this.imageRef = this.$refs.image
    },
    zoomStart() {
      if (!this.canHover) return
      this.isZooming = true
    },
    zoomIn(e) {
      if (!this.canHover) return
      this.width = this.imageRef.offsetWidth
      this.height = this.imageRef.offsetHeight
      this.mouseX = e.offsetX
      this.mouseY = e.offsetY

      this.bgPosX = (this.mouseX / this.width * 100)
      this.bgPosY = (this.mouseY / this.height * 100)
    },
    zoomEnd() {
      if (!this.canHover) return
      this.isZooming = false
    },
    openImagePopup() {
      if (this.hasError) return true
      this.showImagePopup = true
    },
    closeImagePopup() {
      this.showImagePopup = false
    },
    setCanHover(event) {
      this.canHover = event.matches
    }
  },
  created() {
    if (!this.$store.hasModule(this.moduleName)) {
      this.$store.registerModule(this.moduleName, {
        namespaced: true,
        state,
        getters,
        mutations,
        actions
      })

      if (this.defaultSrc || this.defaultAlt) {
        this.$store.dispatch(`${this.moduleName}/setImage`, { src: this.defaultSrc ?? '', alt: this.defaultAlt ?? 'Featured Image' })
      }
    }
  },
  mounted() {
    this.setRef()

    // Only allow if the the users device can hover
    const mql = window.matchMedia('(any-hover: hover)')
    this.canHover = mql.matches
    try {
      mql.addEventListener('change', this.setCanHover)
    } catch (error) {
      try {
        mql.addListener(this.setCanHover)
      } catch (error) {
        console.error(error)
      }
    }
  },
  watch: {
    src(val, oldVal) {
      if (val !== oldVal && this.hasError) this.hasError = false
    }
  }
}
</script>
